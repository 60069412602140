<template>
  <div>
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="350px"
      width="100%"
      ref="LeftChartRef"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
import { debounce } from '@/utils';
const resizeChartMethod = 'bottomLeftChart__resizeChartMethod';
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          backgroundColor: "rgba(255,255,255,1)",
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,1)",
            textStyle: {
              color: "#e94e46"
            },
            axisPointer: {
              type: "cross",
              label: {
                show: true,
                backgroundColor: "#e94e46",
              },
            },
          },
          legend: {
            data: ["标准率", '非准率', "标准数量", "上传总数"],
            textStyle: {
              color: "#e94e46"
            },
            top: "0%"
          },
          grid: {
            x: "8%",
            width: "88%",
            y: "4%"
          },
          xAxis: {
            data: newData.category,
            splitLine: { show: true },
            axisLine: {
              "show": true,
              lineStyle: {
                color: "#e94e46"
              },
            },
            axisLabel: {
              "show": true,
              "textStyle": {
                "color": "#e94e46"
              }
            },
            axisTick: {
              show: true
            }
          },
          yAxis: [
            {
              splitLine: { show: true },
              axisLine: {
                "show": true,
                lineStyle: {
                  color: "#e94e46"
                },
                textStyle: {
                  color: "#e94e46"
                }
              },
              axisLabel: {
                "show": true,
                "textStyle": {
                  "color": "#e94e46"
                },
                formatter: "{value} "
              },
            },
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#e94e46"
                }
              },
              axisLabel: {
                "show": true,
                "textStyle": {
                  "color": "#e94e46"
                },
                formatter: "{value} "
              }
            }
          ],
          series: [
            {
              name: "标准率",
              type: "line",
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' %';
                }
              },
              itemStyle: {
                normal: {
                  color: "#e94e46"
                }
              },
              data: newData.rateData
            },
            {
              name: "非准率",
              type: "line",
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' %';
                }
              },
              itemStyle: {
                normal: {
                  color: "#f4a6a2"
                }
              },
              data: newData.norateData
            },
            {
              name: "标准数量",
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#e94e46" },
                    { offset: 1, color: "#e94e46" }
                  ])
                }
              },
              data: newData.barData
            },
            {
              name: "上传总数",
              type: "bar",
              barGap: "-100%",
              barWidth: 10,
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(156,107,211,1)" },
                    { offset: 0.2, color: "rgba(156,107,211,1)" },
                    { offset: 1, color: "rgba(156,107,211,1)" }
                  ])
                }
              },
              z: -12,
              data: newData.lineData
            }
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
  created() {
    window.addEventListener('resize', this[resizeChartMethod], false);
  },
  activated() {
    // 防止 keep-alive 之后图表变形
    if (this.$refs.LeftChartRef.chart) {
      this.$refs.LeftChartRef.chart.resize()
    }
  },
  beforeDestroy() {
    window.removeEventListener('reisze', this[resizeChartMethod]);
  },
  methods: {
    // 防抖函数来控制 resize 的频率
    [resizeChartMethod]: debounce(function() {
      if (this.$refs.LeftChartRef.chart) {
        this.$refs.LeftChartRef.chart.resize()
      }
    }, 300),
  },
}
</script>