<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import {ajax as $ajax} from "@/utils/libs/utils.http.js";
export default {
  data () {
    return {
      cdata: {
        category: [ // 地区
        ],
        lineData: [ // 上传总数
        ],
        barData: [ // 标准量
        ],
        rateData: [], // 标准率
        norateData: []
      },
      provinceType: localStorage.getItem('provinceType') || '四川'
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.drawTimingFn()
  },
  beforeDestroy () {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn () {
      this.getdata();
      this.drawTiming = setInterval(() => {
        this.getdata();
      }, 150000);
    },
    allUrl (url, params) {
      return  $ajax({
         type: "get",
         dataType: "formData",
         url : url,
         data: params
      })
    },
   async getdata() {
      // 清空轮询数据
      this.cdata.category = [];
      this.cdata.barData = [];
      this.cdata.lineData = [];
      this.cdata.rateData = [];
      this.cdata.norateData = [];

      try {
        let {data} =  await this.allUrl('/pic/statistic/city/std-type-percent/by-type',{durationType: 0, province: this.provinceType})
        let statistic_times =  await this.allUrl('/pic/statistic/city/times/by-type',{durationType: 0, province: this.provinceType})
        data.map((item) => {
          if (item.stdType === '标准') {
            let times_index = statistic_times.data.findIndex((val) => {return val.city === item.city})
            this.cdata.category.push(item.city)
            this.cdata.barData.push(item.times)
            this.cdata.lineData.push(statistic_times.data[times_index].times)
            this.cdata.rateData.push((item.percent.replace('%', '')))
            this.cdata.norateData.push((10000 - (Number(item.percent.replace('%', '')) * 100)) / 100)
          }
        })
      } catch (error) {
      }
    },
    // 根据自己的业务情况修改
    setData () {
      // for (let i = 0; i < this.cdata.barData.length -1; i++) {
      //   let rate = this.cdata.barData[i] / this.cdata.lineData[i];
      //   console.log('rateraterateraterateraterateraterateraterate', rate);
      //   this.cdata.rateData.push(rate.toFixed(2));
      // }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>