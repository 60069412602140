<template>
  <div id="center_statistics">
    <dv-decoration-5 style="width:300px;height:20px;" />
    <div class="up">
        <div
          class="bg-color-black item"
          v-for="item in titleItem"
          :key="item.title"
        >
          <p class="ml-3 colorBlue fw-b fs-xl">{{ item.title }}</p>
          <div>
            <dv-digital-flop
              class="dv-dig-flop ml-1 mt-2 pl-3"
              style="width: 100%"
              :config="item.number"
            />
          </div>
        </div>
    </div>
    <!-- <div class="down">
      <div class="ranking bg-color-black">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1 pl-3">{{dicData[rankingIndex]}}</span>
        <dv-scroll-ranking-board class="dv-scr-rank-board mt-1" :config="ranking" />
      </div>
      <div class="percent">
        <div class="item bg-color-black" style="margin-bottom: 8px" v-if="rate.length > 0">
          <span>今日质控标准率</span>
          <CenterChart
            :id="rate[0].id"
            :tips="rate[0].tips"
            :colorObj="rate[0].colorData"
          />
        </div>
        <div class="item bg-color-black" v-if="rate.length > 0">
          <span>今日质控非标准率</span>
          <CenterChart
            :id="rate[1].id"
            :tips="rate[1].tips"
            :colorObj="rate[1].colorData"
          />
        </div>
        <div class="water">
          <dv-water-level-pond class="dv-wa-le-po" :config="water" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import CenterChart from '@/components/echart/center/centerChartRate'
import {ajax as $ajax} from "@/utils/libs/utils.http.js";
export default {
  data() {
    return {
      titleItem: [],
      rankingIndex: 0,
      dicData: {
        0: '年度地区切面数量排行榜',
        1: '季度地区切面数量排行榜',
        2: '月度地区切面数量排行榜'
      },
      ranking: {
        data: [
        ],
        carousel: 'single',
        unit: '张',
        sort: false,
      },
      water: {
        data: [0],
        shape: 'roundRect',
        formatter: ' ',
        waveNum: 3
      },
      // 今日质控标准率 // 今日质控非标准率
      rate: [
      ],
      provinceType: localStorage.getItem('provinceType') || '四川'
    }
  },
  mounted() {
    this.getInfo()
    this.cityTimes()
    this.changeTiming()
  },
  methods: {
    changeTiming() {
      setInterval(() => {
        // this.getInfo()
      }, 15000)
        this.cityTimes()
    },
    allUrl (url, params) {
      return  $ajax({
         type: "get",
         dataType: "formData",
         url : url,
         data: params
      })
    },
    // 地区切面数量排行榜
    async cityTimes () {
      try {
        // durationType
        // 0-年
        // 1-季度
        // 2-月
        // 3-周
        // 4-天
        // 【查询】固定时间范围城市的质控次数
        let { data } =  await this.allUrl('/pic/statistic/city/times/by-type',{durationType: this.rankingIndex, province: this.provinceType})
        let items = data.map(item => {
          return {
            name: item.city,
            value: item.times
          }
        })
        this.ranking.data = items.sort((a, b) => {
          return b.value - a.value
        })
        if (this.rankingIndex >= 2) {
          this.rankingIndex = 0
        } else {
          this.rankingIndex++
        }
        this.ranking = Object.assign({}, this.ranking)
      } catch (error) {
      }
    },
    async getInfo () {
      try {
        let year_times =  await this.allUrl('/pic/statistic/province/times/by-type',{durationType: 0, province: this.provinceType})
        let month_times =  await this.allUrl('/pic/statistic/province/times/by-type',{durationType: 2, province: this.provinceType})
        let day_times =  await this.allUrl('/pic/statistic/province/times/by-type',{durationType: 4, province: this.provinceType})

        let std_percent =  await this.allUrl('/pic/statistic/province/std-type-percent/by-type',{durationType: 2, province: this.provinceType})
        let day_std_percent = await this.allUrl('/pic/statistic/province/std-type-percent/by-type',{durationType: 4, province: this.provinceType})
        let std_percent_bz = std_percent.data.findIndex((val) => {return val.stdType === '标准'})
        let std_percent_fbz = std_percent.data.findIndex((val) => {return val.stdType === '非标准'})
        let std_percent_jbbz = std_percent.data.findIndex((val) => {return val.stdType === '基本标准'})
        // formatter
        // Math.floor(Math.random()*100+1) 
        this.titleItem = [
          {
            title: '今年累计质控数量',
            number: {
              number: [Number(year_times && year_times.data[0].times) || 0],
              toFixed: 0,
              textAlign: 'center',
              content: '{nt}',
              style: {
                fontSize: 26
              }
            }
          },
          {
            title: '本月累计质控数量',
            number: {
              number: [Number(month_times && month_times.data[0].times || 0)],
              toFixed: 0,
              textAlign: 'center',
              content: '{nt}',
              style: {
                fontSize: 26
              }
            }
          },
          {
            title: '今日累计质控数量',
            number: {
              number: [Number(day_times && day_times.length > 0  ? day_times.data[0].times : 0) || 0],
              toFixed: 0,
              textAlign: 'center',
              content: '{nt}',
              style: {
                fontSize: 26
              }
            }
          },
          {
            title: '本月质控标准率',
            number: {
              number: [Number(std_percent && std_percent.data[std_percent_bz] && std_percent.data[std_percent_bz].percent.replace('%', '')) || 0],
              toFixed: 2,
              textAlign: 'center',
              content: '{nt} %',
              style: {
                fontSize: 26
              }
            }
          },
          {
            title: '本月质控非标准率',
            number: {
              number: [Number(std_percent && std_percent.data[std_percent_fbz] && std_percent.data[std_percent_fbz].percent.replace('%', '') || 0)],
              toFixed: 2,
              textAlign: 'center',
              content: '{nt} %',
              style: {
                fontSize: 26
              }
            }
          },
          {
            title: '本月质控基本标准率',
            number: {
              number: [Number(std_percent && std_percent.data[std_percent_jbbz] && std_percent.data[std_percent_jbbz].percent.replace('%', '') || 0)],
              toFixed: 2,
              textAlign: 'center',
              content: '{nt} %',
              style: {
                fontSize: 26
              }
            }
          }
        ]

        let day_std_percent_bz = day_std_percent.data.findIndex((val) => {return val.stdType === '标准'})
        let day_std_percent_fbz = day_std_percent.data.findIndex((val) => {return val.stdType === '非标准'})
        this.rate = [
          {
          id: 'centerRate1',
          tips: day_std_percent_bz !== -1 ? Number(day_std_percent.data[day_std_percent_bz].percent.replace('%', '')) : 0,
          colorData: {
            textStyle: '#3fc0fb',
            series: {
              color: ['#00bcd44a', 'transparent'],
              dataColor: {
                normal: '#03a9f4',
                shadowColor: '#97e2f5'
              }
            }
          }
        },
        {
          id: 'centerRate2',
          tips: day_std_percent_bz !== -1 ? Number(day_std_percent.data[day_std_percent_fbz].percent.replace('%', '')) : 0,
          colorData: {
            textStyle: '#67e0e3',
            series: {
              color: ['#faf3a378', 'transparent'],
              dataColor: {
                normal: '#ff9800',
                shadowColor: '#fcebad'
              }
            }
          }
        }
        ]
      } catch (error) {
      }
    }
  },
  components: {
    CenterChart
  }
}
</script>

<style lang="scss" scoped>
#center_statistics {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 40px auto;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;
      text-align: center;
      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 40%;
      // flex-wrap: wrap;
      .item {
        width: 100%;
        height: 120px;
        span {
          // margin-top: 8px;
          padding-top: 5px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>
