<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import {ajax as $ajax} from "@/utils/libs/utils.http.js";
export default {
  data () {
    return {
      cdata: {
        category: [ // 地区
        ],
        lineData: [ // 上传总数
        ],
        barData: [ // 标准量
        ],
        rateData: [] // 标准率
      },
      provinceType: localStorage.getItem('provinceType') || '四川'
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.drawTimingFn()
  },
  beforeDestroy () {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn () {
      this.getdata();
      this.drawTiming = setInterval(() => {
        this.getdata();
      }, 150000);
    },
    allUrl (url, params) {
      return  $ajax({
         type: "get",
         dataType: "formData",
         url : url,
         data: params
      })
    },
   async getdata() {
      // 清空轮询数据
      this.cdata.category = [];
      this.cdata.barData = [];
      this.cdata.lineData = [];
      this.cdata.rateData = [];

      try {
        let {data} =  await this.allUrl('/pic/statistic/province/pic-count',{durationType: 0, province: this.provinceType})
        data.map((item) => {
          this.cdata.category.push(item.hosName)
          this.cdata.barData.push(item.total)
        })
      } catch (error) {
      }
    },
    // 根据自己的业务情况修改
    setData () {
      // for (let i = 0; i < this.cdata.barData.length -1; i++) {
      //   let rate = this.cdata.barData[i] / this.cdata.lineData[i];
      //   console.log('rateraterateraterateraterateraterateraterate', rate);
      //   this.cdata.rateData.push(rate.toFixed(2));
      // }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>