<template>
  <div id="bottomChart">
    <div class="bg-color-white" style="background-color: #ffffff;">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">各单位上传数据统计（年度）</span>
        </div>
      </div>
      <div style="width: 100%">
        <BottomChart />
      </div>
    </div>
  </div>
</template>

<script>
import BottomChart from '@/components/echart/bottom/bottomChart'
export default {
  components: {
    BottomChart
  }
}
</script>

<style lang="scss" scoped>
$box-height: 380px;
$box-width: 100%;
#bottomChart {
  padding: 20px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 35px;
    border-radius: 10px;
  }
  .text {
    color: #e94e46;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
